import React, { useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { navigate } from "gatsby"

import { dataURItoBlob } from "../../utils/form-data"

import LearCorp from "../../assets/images/image1-17.jpeg"
import LearLogo from "../../assets/images/logos/lear-corporation-logo.jpg"
import {
  Col2,
  Content,
  FieldSet,
  HeaderWithLogo,
  LCard,
} from "../../style/containers"

import Button from "../../components/button"
import Layout from "../../components/layout"
import Signature from "../../components/Signature"
import StarterContent from "../../components/starterContent"
import Form from "../../components/form/FormHelper"
import { FormikCheckbox } from "../../components/form/checkbox"
import { FormikInput, Error } from "../../components/form"
import { LargeImage } from "../../components/starterContent"

const LearJITPlantInduction = () => {
  const [errorText, setErrorText] = useState([])
  const [signatureDrawn, setSignatureDrawn] = useState()

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(8, "Name must be longer than 7 characters")
      .max(50, "Name must be less than 50 characters")
      .required("Name is required"),
    acceptTraining: Yup.boolean().oneOf(
      [true],
      "You must agree before you can continue"
    ),
  })

  const submit = async (formData, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)
      let res = await submitLear(signature)
      handleSignupResponse(res)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  const handleSignupResponse = res => {
    if (res.error) {
      let error = Object.values(res.error)
      setErrorText(error)
    } else {
      navigate("/form-confirm")
    }
  }

  return (
    <Layout>
      <LCard>
        <FieldSet>
          <HeaderWithLogo>
            <h1>Lear Induction (JIT Plant)</h1>
            <img src={LearLogo} alt="Lear Logo" />
          </HeaderWithLogo>
          <h2>Our Details</h2>
          <Col2>
            <div>
              <p>
                Lear Corporation,
                <br /> Unit 2 Phoenix Way, <br />
                Rainton Bridge Industrial Estate, <br />
                Houghton le Spring, <br />
                Tyne and Wear,
                <br />
                DH4 5SA
              </p>
            </div>
            <div>
              <LargeImage src={LearCorp} alt="Lear Corporation site" />
            </div>
          </Col2>
          <StarterContent form="jit" />
          <Content>
            <hr />
          </Content>
          <Formik
            initialValues={{ name: "", acceptTraining: 0, Signature: null }}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormikCheckbox
                  name="acceptTraining"
                  label="I agree I have received Introduction Training"
                />
                <FormikInput name="name" type="text" label="Full Name" />
                <Signature
                  signatureDrawn={signatureDrawn}
                  setSignatureDrawn={setSignatureDrawn}
                />
                {errorText
                  ? errorText.map(e => (
                      <Error style={{ marginTop: "1rem" }} key={e}>
                        {e}
                      </Error>
                    ))
                  : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  name="new-starter"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </FieldSet>
      </LCard>
    </Layout>
  )
}

export default LearJITPlantInduction
